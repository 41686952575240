import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { loginUser } from "../utils/auth";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email && password) {
      const result = await loginUser(email, password);
      if (result && result.isVerified) {
        window.location.href = "/admin/users";
      } else {
        alert("Woops!");
        setError(result.error);
        setLoading(false);
      }
    } else {
    }
  };

  return (
    <Box>
      <Flex
        // h={"100vh"}
        w={"100%"}
        justifyContent={["", "center", "center", "center"]}
        alignItems={"center"}
      >
        <Flex
          alignItems={["", "center"]}
          flexDir={["column", "column", "column", "row"]}
          justifyContent={["center", "center", "center", "center"]}
        >
          <Flex mt={"20px"} justifyContent={["center", ""]}>
            <Image
              mt={"50px"}
              src="/Assets/login.png"
              w={[350, 350, 600, 600]}
            />
          </Flex>
          <Box w={["", "40px"]}></Box>
          <Box p={3} w={480}>
            <Spacer h={["", "", "", "100px"]} />
            <Text
              fontSize={["xxx-large", ""]}
              textAlign={["center", "", "", "start", "start", "start"]}
              color={"#4272EE"}
              fontWeight={"700"}
            >
              Login In
            </Text>

            <Spacer h={["30px", "50px"]} />
            <Flex justifyContent={"center"}>
              <Input
                placeholder="Enter Email"
                size="lg"
                w={350}
                bg={"#ECF1FD"}
                type={"email"}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              />
            </Flex>
            <Spacer h={"24px"} />
            <Flex justifyContent={"center"}>
              <Input
                placeholder="Password"
                size="lg"
                w={380}
                bg={"#ECF1FD"}
                type={"password"}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
                required
              />
            </Flex>
            <Spacer h={"24px"} />
            <Flex justifyContent={"end"} mr={"36px"} alignItems={"center"}>
              <Button color={"#3C68D9"} _hover={"none"} bg={"white"}>
                Forgot Password?
              </Button>
            </Flex>
            <Spacer h={"60px"} />
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Button
                pl={9}
                pr={9}
                color={"white"}
                bg={"#3C68D9"}
                onClick={handleSubmit}
              >
                Login
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
