import React, { useEffect, useState } from "react";
import { Descriptions } from "antd";
import axios from "axios";
import axiosInstance from "../utils/axiosInstance";
import { APIS } from "../utils/constants";
import { useLocation } from "react-router-dom";

export default function UserDetail() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userDetail, setUser] = useState({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("userId");

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        setLoading(true);
        const url = `${APIS.user_details}?userId=${userId}`;
        const response = await axiosInstance.get(url, {
          cancelToken: source.token,
        });
        setUser(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      source.cancel("Component got unmounted");
    };
  }, []);

  const items = [
    {
      key: "1",
      label: "First Name",
      children: "firstName",
    },
    {
      key: "2",
      label: "Last Name",
      children: "lastName",
    },
    {
      key: "3",
      label: "Bussiness Name",
      children: "bussinessName",
    },
    {
      key: "4",
      label: "Bussiness Phone",

      children: "bussinessPhone",
    },
    {
      key: "5",
      label: "Phone",
      children: "phone",
    },
    {
      key: "6",
      label: "Address1",
      children: "address1",
    },
    {
      key: "7",
      label: "Address2",
      children: "address2",
    },
    {
      key: "8",
      label: "City",
      children: "city",
    },
    {
      key: "9",
      label: "Zip",
      children: "zip",
    },
    {
      key: "10",
      label: "State",
      children: "state",
    },
    {
      key: "11",
      label: "Country",
      children: "country",
    },
    {
      key: "12",
      label: "Business License Number",
      span: 3,
      children: "businessLicenseNumber",
    },
    {
      key: "13",
      label: "Tobacco License Number",
      children: "tobaccoLicenseNumber",
    },
    {
      key: "14",
      label: "Federal EIN Number",
      children: "federalEINNumber",
    },
    {
      key: "15",
      label: "Driving License Number",
      children: "drivingLicenseNumber",
    },
    {
      key: "16",
      label: "Account Number",
      children: "accountNumber",
    },
    {
      key: "13",
      label: "Customer Profile Id",
      children: "customerProfileId",
    },
    {
      key: "14",
      label: "Customer ID",
      children: "customerID",
    },

    {
      key: "15",
      label: "Business License Document",
      span: 3,
      children: "files.BusinessLicenseNumber",
      link: `${userDetail?.files?.BusinessLicenseNumber}`,
      customerID: "15DA",
    },
    {
      key: "16",
      label: "Tobacco License Document",
      span: 3,
      children: "files.TobaccoLicenseNumber",
      link: `${userDetail?.files?.TobaccoLicenseNumber}`,
      customerID: "16DA",
    },

    {
      key: "17",
      label: "Federal EIN Document",
      span: 3,
      children: "files.FederalEINDocumentNumber",
      link: `${userDetail?.files?.FederalEINDocumentNumber}`,
      customerID: "17DA",
    },

    {
      key: "18",
      label: "Driving License Document",
      span: 3,
      children: "files.DrivingLicenseNumber",
      link: `${userDetail?.files?.DrivingLicenseNumber}`,
      customerID: "18DA",
    },

    {
      key: "19",
      label: "Void Cheque Document",
      span: 3,
      children: "files.VoidCheque",
      link: `${userDetail?.files?.VoidCheque}`,
      customerID: "19DA",
    },
  ];

  return (
    <>
      <Descriptions title="User Info" layout="vertical">
        {items.map((item) => {
          return item?.customerID ? (
            <Descriptions.Item
              key={item?.key}
              span={item?.span}
            >
              <a
                href={item?.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "red" }}
              >
                {item.label}
              </a>
            </Descriptions.Item>
          ) : (
            <Descriptions.Item
              key={item?.key}
              label={item?.label}
              span={item?.span}
            >
              {userDetail[item.children]}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </>
  );
}
