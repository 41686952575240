import React, { useEffect, useState } from "react";
import { Descriptions, List, Typography, Avatar, Card } from "antd";
import dateFormat from "dateformat";
import { useOrder } from "../utils/OrderContext";
const { Title } = Typography;

export default function OrderDetail() {
  const { currentOrder } = useOrder();
  const [orderArr, setOrderArr] = useState([]);
  useEffect(() => {
    const organizeProductsByCategory = (products) => {
      const productsByCategory = {};

      products?.forEach((product) => {
        const { groupID, groupName } = product;

        if (!productsByCategory[groupID]) {
          // If the category object doesn't exist, create it
          productsByCategory[groupID] = {
            groupName,
            products: [],
          };
        }

        // Push the product into the corresponding category array
        productsByCategory[groupID].products.push(product);
      });

      // Convert the object of category objects into an array of category objects
      const result = Object.values(productsByCategory);

      return result;
    };

    const organizedProducts = organizeProductsByCategory(
      currentOrder?.productInfo
    );
    setOrderArr(organizedProducts);
  }, []);
  console.log("currentOrder", currentOrder);

  const items = [
    {
      key: "1",
      label: "Invoice No",
      children: "invoiceNo",
    },
    {
      key: "2",
      label: "Date",
      children: "createdAt",
    },
    {
      key: "3",
      label: "Email",
      children: "email",
    },
    {
      key: "4",
      label: "Bussiness Name",
      children: "bussinessName",
    },
    {
      key: "5",
      label: "Customer ID",
      children: "customerID",
    },
  ];

  return (
    <>
      <Card title={"User Info"} style={{ width: "100%" }}>
        <Descriptions style={{ marginTop: "1%" }}>
          {currentOrder?.userDetails &&
            Object.keys(currentOrder?.userDetails)?.length > 0 &&
            items.map((item) => {
              return (
                <Descriptions.Item
                  key={item?.key}
                  label={item?.label}
                  span={item?.span}
                >
                  {item?.children === "invoiceNo"
                    ? currentOrder.invoiceNo
                    : item?.children === "createdAt"
                    ? dateFormat(
                        currentOrder.createdAt,
                        "paddedShortDate",
                        true
                      )
                    : currentOrder?.userDetails[item?.children]}
                </Descriptions.Item>
              );
            })}
        </Descriptions>
      </Card>
      <Card title="Product List" style={{ width: "100%", marginTop: "1%" }}>
        {orderArr.map((orderItem) => {
          return (
            <>
              <Title level={5}>Category Name: {orderItem?.groupName}</Title>
              <div
                id="scrollableDiv"
                style={{
                  overflow: "auto",
                  padding: "0 16px",
                  border: "1px solid rgba(140, 140, 140, 0.35)",
                }}
              >
                <List
                  dataSource={orderItem?.products}
                  renderItem={(item) => (
                    <List.Item key={item?.productID}>
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={` https://qw3images.blob.core.windows.net/products-qw/${item?.code2}.png`}
                          />
                        }
                        title={item?.name}
                      />
                      <div>{item?.quantity}</div>
                    </List.Item>
                  )}
                />
              </div>
            </>
          );
        })}
      </Card>
    </>
  );
}
