import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Flex, Text } from "@chakra-ui/react";
import axiosInstance from "../utils/axiosInstance";
import { APIS } from "../utils/constants";

export default function UploadImages() {
  const [fileList, setFileList] = useState([]);

  const props = {
    beforeUpload: (file) => {
      setFileList((currentFileList) => [...currentFileList, file]);
      return false; // Prevent automatic uploading
    },
    onRemove: (file) => {
      setFileList((currentFileList) =>
        currentFileList.filter((item) => item.uid !== file.uid)
      );
    },
    fileList,
    multiple: true, // Enable multiple file selection
  };

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    try {
      await axiosInstance.post(APIS.update_images, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setFileList([]);
      message.success("Uploaded successfully");
    } catch (error) {
      message.error("Upload failed, please try again or contact admin");
    }
  };

  return (
    <div>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text mb={"8px"} fontSize={"25px"} fontWeight={"700"}>
          Upload Images
        </Text>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Select Files</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          style={{ marginTop: 16 }}
        >
          Upload
        </Button>
      </Flex>
    </div>
  );
}
