import React, { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Table, Tag, Select, Input, Button } from "antd";
import axiosInstance from "../utils/axiosInstance";
import { APIS } from "../utils/constants";
import axios from "axios";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import { useOrder } from "../utils/OrderContext";
const { Option } = Select;

export default function Orders() {
  const [dataSource, setDataSource] = useState([]);
  const [filterOrder, serFilterOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setCurrentOrder } = useOrder();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("bussinessName");
  const currentUser = "AdminUser"; // Replace with actual user identification logic

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(APIS.view_orders, {
          cancelToken: source.token,
        });
        setDataSource(response.data);
        serFilterOrder(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      source.cancel("Component got unmounted");
    };
  }, []);

  // Function to get the color based on order status
  const getStatusTagColor = (status) => {
    switch (status) {
      case "Delivered":
        return "green";
      case "Pending":
        return "orange";
      case "Canceled":
        return "red";
      default:
        return "blue";
    }
  };

  // Handle change in the status dropdown
  const handleStatusChange = (orderId, value) => {
    const newData = dataSource.map((item) => {
      if (item.orderId === orderId) {
        return {
          ...item,
          orderStatus: value,
          updatedBy: currentUser,
          isChanged: true,
        };
      }
      return item;
    });
    setDataSource(newData);
  };
  const handleUpdate = (orderId) => {
    const newData = dataSource.map((item) => {
      if (item.orderId === orderId) {
        console.log("Updated order:", orderId, item);
        return { ...item, isChanged: false }; // Reset change flag
      }
      return item;
    });
    setDataSource(newData);
    // Implement the logic to update the status in your data source here
  };

  const showOrderDetail = (customer) => {
    // navigate(`/admin/order-detail`);
    setCurrentOrder(customer);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: "Customer ID",
      dataIndex: "customerID",
      key: "customerID",
      render: (visible, record) => {
        const recordValue = record ? record.userDetails : "N/A";
        return <span>{recordValue?.customerID}</span>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (visible, record) => {
        return <span>{dateFormat(record?.createdAt, "paddedShortDate", true)}</span>;
      },
    },
    {
      title: "Bussiness Name",
      dataIndex: "bussinessName",
      key: "bussinessName",
      render: (visible, record) => {
        const recordValue = record ? record.userDetails : null;
        return <span>{recordValue?.bussinessName || "N/A"}</span>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) =>
        a?.userDetails?.email?.localeCompare(b?.userDetails?.email),
      render: (visible, record) => {
        const recordValue = record ? record.userDetails : "N/A";
        return <span>{recordValue?.email}</span>;
      },
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (visible, record) => {
        return <span>{record?.invoiceNo}</span>;
      },
    },
    {
      title: "Invoice Link",
      dataIndex: "invoiceLink",
      key: "invoiceLink",
      render: (visible, record) => {
        const recordValue = record ? record.invoiceLink : "#";
        return (
          <a target="_blank" rel="noopener noreferrer" href={recordValue}>
            Click Here
          </a>
        );
      },
    },
    {
      title: "Picking Link",
      dataIndex: "pickingLink",
      key: "pickingLink",
      render: (visible, record) => {
        const recordValue = record ? record?.invoiceLink : "#";
        return (
          <a target="_blank" rel="noopener noreferrer" href={recordValue}>
            Click Here
          </a>
        );
      },
      render: (_, record) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/admin/order-detail"
            onClick={() => showOrderDetail(record)}
          >
            View
          </a>
          // <Button type="link" onClick={() => showOrderDetail(record)}>
          //   View
          // </Button>
        );
      },
    },
    // {
    //   title: "CID",
    //   dataIndex: "customerID",
    //   key: "customerID",
    //   // sorter: (a, b) => a.customerID - b.customerID,
    //   render: (visible, record) => {
    //     const recordValue = record ? record.customerInfo?.customerID : "N/A";
    //     const color = "geekblue";
    //     return (
    //       <Tag color={color} key={record}>
    //         {recordValue}
    //       </Tag>
    //     );
    //   },
    // },
    {
      title: "Total Amount",
      dataIndex: "net",
      key: "net",
      render: (visible, record) => {
        return <span>$ {record?.net}</span>;
      },
    },
  ];

  const searchUser = (e) => {
    if (searchQuery.length > 0 && e.target.value.length === 0) {
      serFilterOrder(dataSource);
    }
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    if (searchQuery && searchQuery?.length > 0) {
      const lowercasedQuery = searchQuery.toLowerCase().trim();
      const updatedFilteredUsers = dataSource.filter((order) => {
        const fieldValue =
          searchField === "invoiceID"
            ? String(order[searchField] || "").toLowerCase()
            : String(order.userDetails[searchField] || "").toLowerCase();
        return fieldValue.includes(lowercasedQuery);
      });
      serFilterOrder(updatedFilteredUsers);
    }
  };
  return (
    <div>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text mb={"8px"} fontSize={"25px"} fontWeight={"700"}>
          All Orders
        </Text>
        <Flex mb={"10px"}>
          <Select
            defaultValue="bussinessName"
            onChange={(value) => {
              value === "allOrder" && serFilterOrder(dataSource);
              setSearchField(value);
            }}
            style={{ width: 120, marginRight: 8 }}
          >
            <Option value="allOrder">All Order</Option>
            <Option value="bussinessName">Bussiness Name</Option>
            <Option value="email">Email</Option>
            <Option value="customerID">Customer ID</Option>
            <Option value="invoiceNo">Invoice No</Option>
          </Select>
          <Input
            placeholder="Search..."
            value={searchQuery}
            onChange={searchUser}
            onPressEnter={handleSearch}
            style={{ width: 200, marginRight: 8 }}
          />
          <Button onClick={handleSearch}>Search</Button>
        </Flex>
      </Flex>
      {filterOrder && (
        <Table scroll={{ x: 400 }} dataSource={filterOrder} columns={columns} />
      )}
    </div>
  );
}
