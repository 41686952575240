import React, { createContext, useContext, useEffect, useState } from "react";

const OrderContext = createContext();

export const useOrder = () => {
  return useContext(OrderContext);
};

export const OrderProvider = ({ children }) => {
  const [currentOrder, setCurrentOrder] = useState(() =>
    JSON.parse(localStorage.getItem("currentOrder"))
  );

  useEffect(() => {
    const storedValue = localStorage.getItem("currentOrder");
    if (storedValue) {
      setCurrentOrder(JSON.parse(storedValue));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("currentOrder", JSON.stringify(currentOrder));
  }, [currentOrder]);

  return (
    <OrderContext.Provider value={{ currentOrder, setCurrentOrder }}>
      {children}
    </OrderContext.Provider>
  );
};
