import React, { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Button, Form, Input, Modal, Select, Table, Tag } from "antd";
import { Switch } from "antd";
import axios from "axios";
import axiosInstance from "../utils/axiosInstance";
import { APIS } from "../utils/constants";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

export default function Users() {
  const [dataSource, setDataSource] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("firstName");

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(APIS.user_accounts, {
          cancelToken: source.token,
        });
        const sortedDataArray = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setDataSource(sortedDataArray);
        setFilteredUsers(sortedDataArray);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      source.cancel("Component got unmounted");
    };
  }, []);

  const verifyUserAPI = async (userId) => {
    if (userId) {
      try {
        setLoading(true);
        await axiosInstance.get(`${APIS.verify_user_account}?userId=${userId}`);
        const updatedData = dataSource.map((item) => {
          if (item.userId === userId) {
            return { ...item, isVerified: true };
          }
          return item;
        });
        setLoading(false);
        return setFilteredUsers(updatedData);
      } catch (error) {
        console.log("Verify User Error:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSwitchChange = async (checked, record) => {
    const userID = record.userId;
    return await verifyUserAPI(userID);
  };
  const searchUser = (e) => {
    if (searchQuery.length > 0 && e.target.value.length === 0) {
      setFilteredUsers(dataSource);
    }
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    if (searchQuery && searchQuery?.length > 0) {
      const lowercasedQuery = searchQuery.toLowerCase().trim();
      const updatedFilteredUsers = dataSource.filter((user) => {
        const fieldValue = String(user[searchField] || "").toLowerCase();
        return fieldValue.includes(lowercasedQuery);
      });
      setFilteredUsers(updatedFilteredUsers);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "userId",
      key: "userId",
      sorter: (a, b) => a.userId?.localeCompare(b.userId),
    },
    {
      title: "CID",
      dataIndex: "customerID",
      key: "customerID",
      sorter: (a, b) => a.customerID - b.customerID,
      render: (record) => {
        const recordValue = record ? record : "N/A";
        const color = record ? "geekblue" : "red";
        return (
          <Tag color={color} key={record}>
            {recordValue}
          </Tag>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt?.localeCompare(b.createdAt),
      render: (record) => {
        const dateOnly = record.split("T")[0];
        return <p>{dateOnly}</p>;
      },
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName?.localeCompare(b.firstName),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.lastName?.localeCompare(b.lastName),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email?.localeCompare(b.email),
    },
    {
      title: "Business Name",
      dataIndex: "bussinessName",
      key: "bussinessName",
      sorter: (a, b) => a.bussinessName?.localeCompare(b.bussinessName),
    },
    {
      title: "Bussiness Phone",
      dataIndex: "bussinessPhone",
      key: "bussinessPhone",
      sorter: (a, b) => a.bussinessPhone?.localeCompare(b.bussinessPhone),
    },
    {
      title: "Verification Status",
      dataIndex: "isVerified",
      key: "isVerified",
      // sorter: (a, b) => a.isVerified - b.isVerified,
      render: (visible, record) => {
        return (
          <Switch
            value={visible}
            disabled={visible}
            onChange={async () => {
              await onSwitchChange(visible, record);
            }}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      render: (_, record) => {
        return (
          <Button type="link" onClick={() => showCustomerModal(record)}>
            View
          </Button>
        );
      },
    },
  ];

  const showCustomerModal = (customer) => {
    navigate(`/admin/user-detail?userId=${customer.userId}`);
    setCurrentCustomer(customer);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    if (name === "customerId") {
      const intValue = parseInt(value, 10);
      if (!isNaN(intValue) && intValue > 0) {
        setCurrentCustomer((prevCustomer) => ({
          ...prevCustomer,
          [name]: intValue,
        }));
      } else {
        console.log(
          "Invalid Customer ID. It must be an integer greater than 0."
        );
        return;
      }
    } else {
      // Update other fields as usual
      setCurrentCustomer((prevCustomer) => ({
        ...prevCustomer,
        [name]: value,
      }));
    }
  };

  const handleModalUpdate = () => {
    const customerID = currentCustomer.customerId;
    const userID = currentCustomer.userId;
    if (customerID && userID) {
      setLoading(true);
      axiosInstance
        .patch(`${APIS.update_user_account}`, {
          userId: userID,
          customerId: customerID,
        })
        .then((res) => {
          const updatedData = dataSource.map((item) => {
            if (item.userId === userID) {
              return { ...item, customerID: customerID };
            }
            return item;
          });

          setDataSource(updatedData);
          setCurrentCustomer();
          setLoading(false);
          setIsModalVisible(false);
        })
        .catch((err) => {
          console.log("CustomerID update User Error:", err);
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  function verifiedUserFilter(verify) {
    const result =
      verify === "allUser"
        ? dataSource
        : dataSource?.filter((user) => user?.isVerified === verify);
    return setFilteredUsers(result);
  }

  return (
    <div>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text mb={"8px"} fontSize={"25px"} fontWeight={"700"}>
          All Users
        </Text>
        <Flex mb={"10px"}>
          <Select
            defaultValue="firstName"
            onChange={(value) => {
              value === "isVerified" && verifiedUserFilter(true);
              value === "notVerified" && verifiedUserFilter(false);
              value === "allUser" && verifiedUserFilter("allUser");
              setSearchField(value);
            }}
            style={{ width: 120, marginRight: 8 }}
          >
            <Option value="allUser">All User</Option>
            <Option value="firstName">First Name</Option>
            <Option value="lastName">Last Name</Option>
            <Option value="email">Email</Option>
            <Option value="isVerified">Is Verified</Option>
            <Option value="notVerified">Not Verified</Option>
          </Select>
          <Input
            placeholder="Search..."
            value={searchQuery}
            onChange={searchUser}
            onPressEnter={handleSearch}
            style={{ width: 200, marginRight: 8 }}
          />
          <Button onClick={handleSearch}>Search</Button>
        </Flex>
      </Flex>
      <Table
        loading={loading}
        scroll={{ x: 400 }}
        columns={columns}
        dataSource={filteredUsers}
      />
      <Modal
        title="Customer Details"
        visible={isModalVisible}
        onOk={handleModalUpdate}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleModalUpdate}>
            Update
          </Button>,
        ]}
      >
        {currentCustomer && (
          <Form layout="vertical">
            <Form.Item label="Customer ID">
              <Input
                size="large"
                name="customerId"
                placeholder="Enter Customer ID"
                disabled={
                  currentCustomer?.customerID && currentCustomer?.customerID > 0
                }
                value={currentCustomer?.customerID}
                onChange={handleModalChange}
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                size="large"
                name="email"
                placeholder="Enter Email"
                disabled
                value={currentCustomer.email}
              />
            </Form.Item>
            <Form.Item label="First Name">
              <Input
                size="large"
                name="firstName"
                placeholder="Enter First Name"
                disabled
                value={currentCustomer?.firstName}
              />
            </Form.Item>
            <Form.Item label="Last Name">
              <Input
                size="large"
                name="lastName"
                placeholder="Enter Last Name"
                disabled
                value={currentCustomer?.lastName}
              />
            </Form.Item>
            <Form.Item label="User ID">
              <Input
                size="large"
                name="userID"
                placeholder="Enter User ID"
                disabled
                value={currentCustomer.userId}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
}
