import React, { useEffect, useState } from "react";
import { Spacer } from "@chakra-ui/react";
import { Input, Space, Modal, Table, Tag, Button, Popconfirm } from "antd";
import axios from "axios";
import axiosInstance from "../utils/axiosInstance";
import { APIS } from "../utils/constants";
import { Text } from "@chakra-ui/react";
import { EditOutlined } from "@ant-design/icons";

export default function Inquiry() {
  const [dataSource, setDataSource] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [edit, setEdit] = useState({});

  const handleEdit = (record) => {
    // Handle the edit action for the corresponding record
    console.log(`Editing record with message: ${record.message}`);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(APIS.inquiry, {
          cancelToken: source.token,
        });
        const sortedDataArray = response.data.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setDataSource(sortedDataArray);
      } catch (error) {
        alert(error.message);
      } finally {
      }
    };
    fetchData();
    return;
  }, []);

  const getStatusColor = (status) => {
    if (status === "pending") return "orange";
    else {
      return "green";
    }
  };

  const confirmInquiry = async (record) => {
    try {
      const source = axios.CancelToken.source();
      const response = await axiosInstance.patch(
        `${APIS.confirm_inquiry}/${record._id}/mark-as-done`,
        {
          cancelToken: source.token,
        }
      );
      if (response.data.success) {
        const sortedDataArray = dataSource.reduce((acc, inquiry) => {
          if (inquiry._id === record._id) {
            acc.push({
              ...inquiry,
              status: "done",
            });
          } else {
            acc.push(inquiry);
          }
          return acc;
        }, []);
        setDataSource(sortedDataArray);
        alert("Inquiry confirmed successfully");
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const deleteInquiry = async (record) => {
    try {
      const source = axios.CancelToken.source();
      const response = await axiosInstance.delete(
        `${APIS.confirm_inquiry}/${record._id}/mark-as-delete`,
        {
          cancelToken: source.token,
        }
      );
      if (response.data.success) {
        const sortedDataArray = dataSource.filter(
          (inquiry) => inquiry._id !== record._id
        );
        setDataSource(sortedDataArray);
        alert("Inquiry deleted successfully");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => {
        const dateOnly = record.split("T")[0];
        return <p>{dateOnly}</p>;
      },
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status?.localeCompare(b.status),
      render: (record) => {
        const name = record.toLowerCase();
        return <Tag color={getStatusColor(name)}>{name}</Tag>;
      },
    },
    {
      title: "Change Status",
      dataIndex: "message",
      key: "message",
      render: (text, record) => (
        <Popconfirm
          placement="bottomRight"
          title="Confirm inquiry"
          description="Are you sure to confirm this inquiry?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => confirmInquiry(record)}
        >
          <Button>Confirm Inquiry</Button>
        </Popconfirm>
      ),
    },
    {
      title: "Delete",
      dataIndex: "message",
      key: "message",
      render: (text, record) => (
        <Popconfirm
          placement="bottomRight"
          title="Delete the inquiry"
          description="Are you sure to delete this inquiry?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => deleteInquiry(record)}
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
  ];

  const deleteOnUser = (record) => {
    Modal.confirm({
      title: "are you sure delete this row",
      onOk: () => {
        setDataSource((pre) => {
          return pre.filter((student) => student.id != record.id);
        });
      },
    });
  };
  const editUserRow = (record) => {
    setIsEditing(true);
    setEdit({ ...record });
  };

  const resetEditing = () => {
    setIsEditing(false);
    setEdit({});
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEdit({
      ...edit,
      [name]: value,
    });
  };
  return (
    // <Layout>
    <div>
      <Text mb={"8px"} fontSize={"25px"} fontWeight={"700"}>
        All Inquiry
      </Text>
      <Table scroll={{ x: 400 }} columns={columns} dataSource={dataSource} />
      <Modal
        title="User Edit"
        open={isEditing}
        okText="Save"
        onCancel={() => {
          resetEditing();
        }}
        onOk={() => {
          setDataSource((pre) => {
            return pre.map((user) => {
              if (user.id === edit.id) {
                return edit;
              } else {
                return user;
              }
            });
          });
          resetEditing();
        }}
      >
        {Object.keys(edit).map(
          (i) => (
            console.log(i),
            (
              <>
                <Input
                  type="text"
                  name={i}
                  value={edit[i]}
                  onChange={handleInputChange}
                />
                <Spacer h={"20px"} />
              </>
            )
          )
        )}
      </Modal>
    </div>
    // </Layout>
  );
}
