import {
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Text,
  Link,
} from "@chakra-ui/react";
import {
  FiTrendingUp,
  FiCompass,
  FiLock,
  FiUser,
  FiUpload,
} from "react-icons/fi";
import { useUser } from "../utils/UserContext";

const LinkItems = [
  { name: "Users", icon: FiUser, link: "/admin/users" },
  { name: "Orders", icon: FiCompass, link: "/admin/orders" },
  { name: "Products", icon: FiCompass, link: "/admin/products" },
  {
    name: "Change Password",
    icon: FiLock,
    link: "/admin/user/change-password",
  },
  { name: "Inquiry", icon: FiTrendingUp, link: "/admin/contact" },
  { name: "Upload", icon: FiUpload, link: "/admin/upload" },
];

const LinkItemsSubAdmin = [
  { name: "Orders", icon: FiCompass, link: "/admin/orders" },
  { name: "Upload", icon: FiUpload, link: "/admin/upload" },
];
const SidebarWithHeader = ({ onClose, ...rest }) => {
  const { user } = useUser();
  const isSubAdmin = user?.hasFullAdminAccess ? false : true;
  const subItemsToMap = isSubAdmin ? LinkItemsSubAdmin : LinkItems;
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        h="20"
        flexDirection="column"
        alignItems="center"
        mx="8"
        mt={2}
        justifyContent="space-between"
      >
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          ADMIN
        </Text>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {subItemsToMap.map((link) => (
        <NavItem key={link.name} icon={link.icon} link={link.link}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, link, ...rest }) => {
  return (
    <Link href={link}>
      <Box>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "#b9f2ff",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "white",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Box>
    </Link>
  );
};
export default SidebarWithHeader;
