import axios from "axios";
import Cookies from "js-cookie";
import { JWT_COOKIE } from "./constants";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_serverUrl}`,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get(JWT_COOKIE);
    // console.log("token", token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Optionally, you can manipulate response or handle logging
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Token is invalid or expired
      // Redirect to login or refresh token
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
