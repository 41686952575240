import React from "react";
import { Box } from "@chakra-ui/react";
import AdminLayout from "../AdminLayout";

const Layout = ({ children }) => {
  const currentPath = window.location.pathname;
  const adminPath = currentPath.includes("admin");
  return (
    <Box>
      {adminPath ? (
        <>
          <AdminLayout children={children} />
        </>
      ) : (
        <main>{children}</main>
      )}
    </Box>
  );
};
export default Layout;
