export const googleMapsLink =
  "https://www.google.com/maps/place/Quality+wholesale+usa/@33.4301684,-94.0837575,17z/data=!3m1!4b1!4m6!3m5!1s0x863441935412b583:0xf7af3086db8aba28!8m2!3d33.4301639!4d-94.0811826!16s%2Fg%2F11sjdkt_2l?entry=ttu";

export const reCAPTCHA_KEY = "6LfAbD0pAAAAABcldosqrTASc2AuOd4QVKEKl5Ss";

export const APIS = {
  verify_user_account: "/admin/account/verify",
  update_user_account: "/admin/account/details",
  update_images: "admin/product/images",
  view_orders: "admin/order/all",
  update_product_feature: "admin/product/update/feature",
  user_accounts: "/admin/account/all",
  products: "/admin/product/all",
  change_password: "/admin/account/change/password",
  inquiry: "/admin/inquiry/all",
  confirm_inquiry: "/admin/inquiry",
  user_details: "/admin/account/details"
};

export const ADMIN_APIS = {
  all_users: "/item/tree",
};

export const DEFAULT_PRODUCT_IMAGE = "/assets/defaultProductImage.png";

export const JWT_COOKIE = "QW_ADMIN_COOKIE";
