import React, { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Table, Image, Tag, Select, Button, Switch, Input } from "antd";
import Papa from "papaparse";
import { jsPDF } from "jspdf";
import axios from "axios";
import axiosInstance from "../utils/axiosInstance";
import { APIS, DEFAULT_PRODUCT_IMAGE } from "../utils/constants";
import "jspdf-autotable";
const { Option } = Select;

export default function Products() {
  const [pageTitle, setPageTitle] = useState("Products");
  const [initialProducts, setInitialProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("code2");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalCost, setTotalCose] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const handlePageChange = (page, pagesize) => {
    setCurrentPage(page);
    setPageSize(pagesize);
  };
  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(APIS.products, {
          cancelToken: source.token,
        });
        setFilteredProducts(response.data);
        setInitialProducts(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          setError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      source.cancel("Component got unmounted");
    };
  }, []);
  useEffect(() => {
    const totalCostMeasure = filteredProducts?.reduce((sum, item) => {
      return item?.warehouses[1]?.free > 0
        ? sum + item.cost * item?.warehouses[1]?.free
        : sum;
    }, 0);
    const totalPriceMeasure = filteredProducts?.reduce((sum, item) => {
      return item?.warehouses[1]?.free > 0
        ? sum + item.price * item?.warehouses[1]?.free
        : sum;
    }, 0);

    setTotalCose(parseInt(totalCostMeasure, 10));
    setTotalPrice(parseInt(totalPriceMeasure, 10));
  }, [filteredProducts]);
  const getStockStatusColor = (quantity) => {
    if (quantity < 0) return "red";
    if (quantity === 0) return "yellow";
    return "green";
  };

  const getStatusColor = (status) => {
    if (status === "active") return "green";
    else {
      return "orange";
    }
  };

  const handleSearch = () => {
    if (searchQuery && searchQuery.length > 0) {
      const lowercasedQuery = searchQuery.toLowerCase().trim();
      const updatedFilteredProducts = initialProducts.filter((product) => {
        if (searchField === "free") {
          const fieldValue = String(
            product.warehouses[1][searchField] || ""
          ).toLowerCase();
          return fieldValue.includes(lowercasedQuery);
        } else {
          const fieldValue = String(product[searchField] || "").toLowerCase();
          return fieldValue.includes(lowercasedQuery);
        }
      });
            setFilteredProducts(updatedFilteredProducts);
    }
  };

  const handleFilterChange = (value) => {
    let filtered = [];
    switch (value) {
      case "available":
        filtered = initialProducts.filter(
          (product) => product.warehouses?.[1].free > 0
        );
        break;
      case "unavailable":
        filtered = initialProducts.filter(
          (product) => product.warehouses?.[1].free === 0
        );
        break;
      case "error":
        filtered = initialProducts.filter(
          (product) => product.warehouses?.[1].free < 0
        );
        break;
      default:
        filtered = [...initialProducts];
    }
    setPageTitle(
      value?.charAt(0)?.toUpperCase() + value?.slice(1) + " Products"
    );
    setFilteredProducts(filtered);
  };

  const handleVisibilityChange = (productId, newVisibility) => {
    const updatedProducts = filteredProducts.map((product) =>
      product.key === productId
        ? { ...product, visibleInWebsite: newVisibility, needsUpdate: true }
        : product
    );
    setFilteredProducts(updatedProducts);
  };

  const handleUpdate = (productId) => {
    // Perform the update operation (e.g., update the backend)
    // After updating, set needsUpdate to false
    const updatedProducts = filteredProducts.map((product) =>
      product.key === productId ? { ...product, needsUpdate: false } : product
    );
    setFilteredProducts(updatedProducts);
  };

  const handlePopularProductsToggle = (productId, value) => {
    setLoading(true);
    axiosInstance
      .patch(`${APIS.update_product_feature}`, {
        productID: productId,
        isInPopular: value,
      })
      .then((res) => {
        if (res.data) {
          const updatedProducts = filteredProducts.map((product) =>
            product.productID === productId
              ? {
                  ...product,
                  isInPopular: !product.isInPopular,
                }
              : product
          );
          setFilteredProducts(updatedProducts);
          setInitialProducts(updatedProducts);
        } else {
          alert("Product did not added in popular product");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("CustomerID update User Error:", err);
        setError(err);
        alert(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleNewArrivalsToggle = (productId, value) => {
    setLoading(true);
    axiosInstance
      .patch(`${APIS.update_product_feature}`, {
        productID: productId,
        isInNewArrival: value,
      })
      .then((res) => {
        if (res.data) {
          const updatedProducts = filteredProducts.map((product) =>
            product.productID === productId
              ? {
                  ...product,
                  isInNewArrival: !product.isInNewArrival,
                }
              : product
          );
          setFilteredProducts(updatedProducts);
          setInitialProducts(updatedProducts);
        } else {
          alert("Product did not added in new arriaval product");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("CustomerID update User Error:", err);
        setError(err);
        alert(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const searchProduct = (e) => {
    if (searchQuery.length > 0 && e.target.value.length === 0) {
      handleFilterChange();
    }
    setSearchQuery(e.target.value);
  };
  const handleDealsToggle = (productId, value) => {
    setLoading(true);
    axiosInstance
      .patch(`${APIS.update_product_feature}`, {
        productID: productId,
        isInDeals: value,
      })
      .then((res) => {
        if (res.data) {
          const updatedProducts = filteredProducts.map((product) =>
            product.productID === productId
              ? {
                  ...product,
                  isInDeals: !product.isInDeals,
                }
              : product
          );
          setFilteredProducts(updatedProducts);
          setInitialProducts(updatedProducts);
        } else {
          alert("Product did not added in deals product");
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("CustomerID update User Error:", err);
        setError(err);
        alert(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportToCsv = (filteredProducts) => {
    const csv = Papa.unparse(filteredProducts);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${pageTitle}.csv`;
    link.click();
  };

  const exportToPdf = (filteredProducts) => {
    const doc = new jsPDF();

    const tableColumn = [
      "ID",
      "UPC",
      "Name",
      "Quantity",
      "Price",
      "Visible in Website",
    ];
    const tableRows = [];

    filteredProducts.forEach((product) => {
      const productData = [
        product.productID,
        product.code2,
        product.name,
        product.warehouses?.[1].free,
        product.cost,
        product.displayed_in_webshop,
      ];
      tableRows.push(productData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.text(`${pageTitle}`, 14, 15);
    doc.save(`${pageTitle}.pdf`);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "productID",
      key: "productID",
      sorter: (a, b) => a.productID - b.productID,
    },
    {
      title: "UPC",
      dataIndex: "code2", // Assuming 'code' is the UPC
      key: "code2",
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name?.localeCompare(b.name),
      render: (record) => {
        const name = record;
        return <span>{name}</span>;
      },
    },
    {
      title: "Image",
      dataIndex: "code2",
      key: "code2",
      render: (record) => (
        <Image
          width={50}
          src={`https://qw3images.blob.core.windows.net/products-qw/${record}.png`}
          alt="Product Image"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = DEFAULT_PRODUCT_IMAGE;
          }}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
    },
    {
      title: "Stock",
      dataIndex: ["stockInfo", "stock"],
      key: "stock",
      sorter: (a, b) => a.warehouses?.[1]?.free - b.warehouses?.[1]?.free,
      render: (text, record) => (
        <Tag color={getStockStatusColor(record?.warehouses?.[1]?.free)}>
          {record.warehouses?.[1]?.free}
        </Tag>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status?.localeCompare(b.status),
      render: (record) => {
        const name = record?.toLowerCase();
        // return <span>{name}</span>;
        return <Tag color={getStatusColor(name)}>{name}</Tag>;
      },
    },
    {
      title: "Visible in Website",
      dataIndex: "displayedInWebshop",
      key: "displayedInWebshop",
      render: (visible, record) => (
        <Switch
          disabled
          checked={visible}
          onChange={() => handleVisibilityChange(record.key, !visible)}
        />
      ),
    },
    {
      title: "In Popular Products",
      dataIndex: "isInPopular",
      key: "isInPopular",
      render: (visible, record) => (
        <Switch
          checked={visible}
          onChange={() =>
            handlePopularProductsToggle(record.productID, !visible)
          }
        />
      ),
    },
    {
      title: "In New Arrivals",
      dataIndex: "isInNewArrival",
      key: "isInNewArrival",
      render: (visible, record) => (
        <Switch
          checked={visible}
          onChange={() => handleNewArrivalsToggle(record.productID, !visible)}
        />
      ),
    },
    {
      title: "In Deals",
      dataIndex: "isInDeals",
      key: "isInDeals",
      render: (visible, record) => (
        <Switch
          checked={visible}
          onChange={() => handleDealsToggle(record.productID, !visible)}
        />
      ),
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (_, record) => (
    //     <Button type="primary" onClick={() => handleUpdate(record.key)}>
    //       Update
    //     </Button>
    //   ),
    // },
  ];

  if (loading) {
    return <p>Loading...</p>; // Or use a spinner/loading component
  }

  if (error) {
    return <p>Error loading data: {error.message}</p>;
  }

  return (
    <div>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text mb={"8px"} fontSize={"25px"} fontWeight={"700"}>
          {pageTitle}
        </Text>
      </Flex>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Select
          defaultValue="all"
          onChange={handleFilterChange}
          style={{
            width: 200,
            marginBottom: "20px",
            borderRadius: "4px",
            borderColor: "#1890ff",
          }}
        >
          <Option value="all">All</Option>
          <Option
            value="available"
            style={{
              color: "green",
            }}
          >
            Available
          </Option>
          <Option
            value="unavailable"
            style={{
              color: "orange",
            }}
          >
            Unavailable
          </Option>
          <Option
            value="error"
            style={{
              color: "red",
            }}
          >
            Error
          </Option>
        </Select>
        <Text fontSize={"18px"} fontWeight={"700"}>
          Total {pageTitle}: {filteredProducts?.length?.toLocaleString("en-US")}
        </Text>
        {initialProducts.length === filteredProducts.length && (
          <>
            <Text fontSize={"18px"} fontWeight={"700"}>
              Total Cost: {totalCost?.toLocaleString("en-US")}
            </Text>
            <Text fontSize={"18px"} fontWeight={"700"}>
              Total Price: {totalPrice?.toLocaleString("en-US")}
            </Text>
          </>
        )}
        <Flex direction="column" alignItems={"start"} mb={"20px"}>
          <Flex mb={"10px"}>
            <Select
              defaultValue="code2"
              onChange={(value) => setSearchField(value)}
              style={{ width: 120, marginRight: 8 }}
            >
              <Option value="code2">UPC</Option>
              <Option value="name">Name</Option>
              <Option value="free">Stock</Option>
              <Option value="price">Price</Option>
            </Select>
            <Input
              placeholder="Search..."
              value={searchQuery}
              onChange={searchProduct}
              onPressEnter={handleSearch}
              style={{ width: 200, marginRight: 8 }}
            />
            <Button onClick={handleSearch}>Search</Button>
          </Flex>
          <Flex>
            <Button
              onClick={() => exportToCsv(filteredProducts)}
              style={{ marginRight: "10px" }}
            >
              Export to CSV
            </Button>
            <Button onClick={() => exportToPdf(filteredProducts)}>
              Export to PDF
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Table
        loading={loading}
        scroll={{ x: 400 }}
        dataSource={filteredProducts}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          onChange: handlePageChange,
        }}
      />
    </div>
  );
}
