import { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { JWT_COOKIE } from "./constants";
import { logoutUser } from "./auth";

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const token = Cookies.get(JWT_COOKIE);
    if (token) {
      try {
        const decoded = jwtDecode(token);
        return {
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          isAdmin: decoded.isAdmin,
          userId: decoded.userId,
          email: decoded.email,
          hasFullAdminAccess: decoded?.hasFullAdminAccess,
          isVerified: decoded.isVerified,
        };
      } catch (error) {
        return null;
      }
    }
    return null;
  });

  const logout = async () => {
    await logoutUser(); // Call the logoutUser function you created above
    setUser(null); // Reset user state to indicate no user is logged in
  };

  return (
    <UserContext.Provider value={{ user, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};
