import React, { useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Flex, Text } from "@chakra-ui/react";
import axiosInstance from "../utils/axiosInstance";
import { APIS } from "../utils/constants";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    console.log("Success:", values);
    try {
      const response = await axiosInstance.post(APIS.change_password, values);
      console.log("response", response);
      if (response.data === true) {
        form.resetFields();
        setLoading(false);
        alert("Password changed successfully");
      }
    } catch (error) {
      setLoading(false);
      alert(error.message);
      alert("Error: " + error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newPassword") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Confirm passwords do not match!"));
    },
  });

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Text mb={"8px"} fontSize={"25px"} fontWeight={"700"}>
          Change Password
        </Text>
      </Flex>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        <Form.Item
          label="userID"
          name="userID"
          rules={[{ required: true, message: "Please input your user Id!" }]}
        >
          <Input disabled={loading} />
        </Form.Item>

        <Form.Item
          label="newPassword"
          name="newPassword"
          hasFeedback
          rules={[
            { required: true, message: "Please input your new password!" },
            { min: 8, message: "Password must be at least 8 characters long" },
            {
              pattern: new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?/~\\\\-]).*$"
              ),
              message:
                "At least one special character, one capital letter, and one number are required",
            },
          ]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmNewPassword"
          hasFeedback
          rules={[
            { required: true, message: "Please input your confirm password!" },
            validateConfirmPassword,
          ]}
        >
          <Input.Password disabled={loading} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangePassword;
