import axios from "axios";
import Cookies from "js-cookie";
import { JWT_COOKIE } from "./constants";

export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_serverUrl}/auth/admin/login`,
      {
        email,
        password,
      }
    );
    const token = response.data.access_token;
    const isVerified = response.data.isVerified;
    Cookies.set(JWT_COOKIE, token, { expires: 1 / 12 }); // 2 hours (1/12 of a day)

    return { success: true, isVerified: isVerified };
  } catch (error) {
    return { success: false, error: error.response };
  }
};

export const logoutUser = async () => {
  Cookies.remove(JWT_COOKIE);

  // Optional: Call an API endpoint to invalidate the token on the server
  // await axios.post('BACKEND_API_URL/logout');
};
