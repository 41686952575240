import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
// import Admin from "./admin";
import Users from "./admin/Users";
// import { Flex, Text } from "@chakra-ui/react";
import Layout from "./components/layout/Layout";
import Login from "./admin/Login";
import Inquiry from "./admin/Inquiry";
import Orders from "./admin/Orders";
import Products from "./admin/Products";
import UserDetail from "./components/UserDetail";
import Upload from "./admin/Upload";
import ChangePassword from "./admin/ChangePassword";
import AdminLayout from "./components/AdminLayout";
import OrderDetail from "./components/OrderDetail";
import { UserProvider } from "./utils/UserContext";
import { OrderProvider } from "./utils/OrderContext";
import "./App.css";

function App() {
  return (
    <>
      <UserProvider>
        <OrderProvider>
          <Layout>
            <Router>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/admin" element={<AdminLayout />} />
                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/user-detail" element={<UserDetail />} />
                <Route path="/admin/contact" element={<Inquiry />} />
                <Route path="/admin/orders" element={<Orders />} />
                <Route path="/admin/order-detail" element={<OrderDetail />} />
                <Route path="/admin/products" element={<Products />} />
                <Route path="/admin/upload" element={<Upload />} />
                <Route
                  path="/admin/user/change-password"
                  element={<ChangePassword />}
                />
              </Routes>
            </Router>
          </Layout>
        </OrderProvider>
      </UserProvider>
    </>
  );
}

export default App;
